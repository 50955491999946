<template>
  <custom-collapse
    class="print-trip-task"
    headerClass="print-trip-task-header"
    :open.sync="isLocalOpen">
    <template #header>
      <div class="fulfilment-timeslot">
        {{ task.rangeLabel }}
      </div>
      <div class="task-pickup-label">
        <b-tag
          v-if="task.isCancelled"
          type="is-danger">
          {{ $t('tasks.status.cancelled') }}
        </b-tag>
        <b-tag v-else-if="task.isPickup">
          {{ $t('print.trip.task.pickupLabel') }}
        </b-tag>
      </div>
      <div class="task-info">
        {{ taskInfo }}
        <span v-if="task.courierNameOverride">
          ({{ task.courierNameOverride }})
        </span>
      </div>
      <div class="task-checkbox">
        <b-checkbox
          v-model="localCheckedTasks"
          :nativeValue="task.id"
          :disabled="isDisabled"
          class="checkbox-only"
          @click.native.stop/>
      </div>
    </template>
    <div class="print-trip-task-content">
      <div class="customer-info">
        <div class="customer-name">
          {{ task.customerName }}
        </div>
        <div class="customer-contact-number">
          {{ task.customerContactNumber }}
        </div>
        <div class="fulfilment-address">
          {{ task.deliveryAddress }}
        </div>
      </div>
      <div class="order-items">
        <div
          v-for="item in task.orderItems"
          :key="`print_trip_task_order_item_${item.id}`"
          class="order-item">
          <div class="order-item-info">
            <div class="order-item-name">
              {{ getItemDisplayName(item) }}
            </div>
            <div class="order-item-quantity">
              {{ $t('print.trip.task.item.quantity', { count: item.multiplierValue }) }}
            </div>
          </div>
          <template v-if="item.meals.length > 1">
            <div
              v-for="(component, index) in item.meals"
              :key="`print_trip_task_order_item_${item.id}_component_${index}`"
              class="order-item-component">
              <div class="order-item-component-name">
                {{ component.name }}
              </div>
              <div class="order-item-component-quantity">
                {{ $t('print.trip.task.item.quantity', { count: component.quantity }) }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </custom-collapse>
</template>

<script>
const CustomCollapse = () => import('@components/CustomCollapse')

export default {
  name: 'print-trip-task',
  components: {
    CustomCollapse
  },
  props: {
    task: {
      type: Object,
      required: true
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    checkedTasks: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isLocalOpen: this.isOpen
    }
  },
  computed: {
    taskInfo() {
      const count = this.task.orderItems.length
      const numOrderItemsLabel = this.$tc('print.trip.task.numOrderItemsLabel', count, { count })
      const taskInfo = this.$t('print.trip.task.taskInfo', { identifier: this.task.orderIdentifier, numOrderItemsLabel })
      if (this.task.externalLogisticsId) {
        const shortId = this.$t(`common.task.shortId.${this.task.externalLogisticsType || 'pickupp'}`, { shortId: this.task.shortId })
        return this.$t('print.trip.task.taskInfoWithShortId', { shortId, taskInfo })
      }
      return taskInfo
    },
    isDisabled() {
      // allow unchecking if checked even if cancelled
      if (this.localCheckedTasks.includes(this.task.id)) {
        return false
      }
      return this.task.isCancelled
    },
    localCheckedTasks: {
      get() {
        return this.checkedTasks
      },
      set(value) {
        this.$emit('update:checkedTasks', value)
      }
    }
  },
  watch: {
    isOpen: {
      handler() {
        if (this.isOpen) {
          this.isLocalOpen = true
        }
      }
    }
  },
  methods: {
    open() {
      this.isLocalOpen = true
    },
    getItemDisplayName(item) {
      if (item.multiplierType === 'quantity' && item.meals.length === 1) {
        return item.meals[0].name
      }
      return item.bundleTemplateName
    }
  }
}
</script>

<style lang="scss" scoped>
.print-trip-task {
  &:not(:last-child) {
    padding-bottom: $space-xs;
    margin-bottom: $space-xs;
    border-bottom: 1px solid $grey-lighter;
  }

  &::v-deep .custom-collapse-header.print-trip-task-header {
    padding: 0;
    font-weight: normal;
    @extend %body;

    > :nth-last-child(2) {
      flex-grow: 1;
    }

    .fulfilment-timeslot {
      min-width: 140px;
      font-weight: bold;
      color: $grey-darker;
    }

    .task-info {
      color: $black;
    }

    .task-pickup-label {
      min-width: 80px;

      .tag:not(.is-danger) {
        background-color: $grey-lighter;
      }
    }

    .task-checkbox {
      &::v-deep {
        .b-checkbox {
          vertical-align: text-bottom;
        }
      }
    }
  }

  .print-trip-task-content {
    display: flex;
    align-items: flex-start;
    padding: $space-xs;
    margin-top: $space-xs;
    background-color: $white-bis;

    > * {
      flex-grow: 1;
    }

    .customer-info {
      padding-right: $space-xs;
      color: $black;
      @extend %body;

      .customer-name,
      .customer-contact-number {
        font-weight: bold;
      }
    }

    .order-items {
      @extend %small;

      .order-item-info,
      .order-item-component {
        display: flex;

        .order-item-name,
        .order-item-component-name {
          flex-grow: 1;
          padding-right: $space-xs;
        }
      }

      .order-item-info {
        font-weight: bold;
      }

      .order-item-component {
        color: $grey;
      }
    }
  }
}
</style>
